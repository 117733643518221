













import { defineComponent } from '@vue/composition-api'

import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import PageView from '@/components/templates/h/yosan-haibun/low-rank-logic-conf/PageView.vue'
import { ColumnLabel } from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
} from '@/types/generated/graphql'
import { EXPORT_FILENAME } from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'

export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      resourceType: ResourceType.UiPLowRankLogicConf,
      templateFile: TemplateFile.UiPLowRankLogicConf_01,
    }
  },
  methods: {
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /**
     * エクスポート処理
     */
    async requestExport() {
      const query = this._createQueryString()
      await this.helper.export({
        query,
        jmespathQuery:
          'data.vUiPLowRankLogicConfs.edges[].node | map(&merge(`{"__update": null}`, @), @)',
        templateFile: this.templateFile,
        filenameExport: EXPORT_FILENAME.low_rank_logic_conf,
        columnLabelMapping: this._create_column_label_mapping(),
      })
    },
    /**
     * ファイル抽出Queryを構築する
     */
    _createQueryString(): string {
      const orderBy = this._createOrderByStatement()
      const where: Where[] = []
      const rowQuery = `
        query c {
          vUiPLowRankLogicConfs(
            orderBy: ${this.helper.generateQueryOrderByString(orderBy)}
          ) {
            edges {
              node {
                inactiveFlg
                storeRank
                storeinDowPatternId
                minWeeklyOrderNum
                minDailyOrderNum1Mon
                minDailyOrderNum1Tue
                minDailyOrderNum1Wed
                minDailyOrderNum1Thu
                minDailyOrderNum1Fri
                minDailyOrderNum1Sat
                minDailyOrderNum1Sun
                minDailyOrderNum2Mon
                minDailyOrderNum2Tue
                minDailyOrderNum2Wed
                minDailyOrderNum2Thu
                minDailyOrderNum2Fri
                minDailyOrderNum2Sat
                minDailyOrderNum2Sun
                configuredFlg
                lastModifiedDatetime
                lastModifiedUserName
              }
            }
          }
        }
        `
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where,
      })
    },
    /**
     * ファイル抽出QueryのOrderByパラメータを構築する
     */
    _createOrderByStatement(): OrderBy[] {
      return [
        {
          field: 'storeRank',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'storeinDowPatternId',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'minWeeklyOrderNum',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
    },
    _create_column_label_mapping() {
      const columnLabelMapping: ColumnLabel[] = [
        {
          from: '__update',
          to: '',
        },
        {
          from: 'inactiveFlg',
          to: '',
        },
        {
          from: 'storeRank',
          to: '',
        },
        {
          from: 'storeinDowPatternDisp',
          to: '',
        },
        {
          from: 'storeinDowPatternName',
          to: '',
        },
        {
          from: 'minWeeklyOrderNum',
          to: '',
        },
        {
          from: 'minDailyOrderNum1Mon',
          to: '',
        },
        {
          from: 'minDailyOrderNum1Tue',
          to: '',
        },
        {
          from: 'minDailyOrderNum1Wed',
          to: '',
        },
        {
          from: 'minDailyOrderNum1Thu',
          to: '',
        },
        {
          from: 'minDailyOrderNum1Fri',
          to: '',
        },
        {
          from: 'minDailyOrderNum1Sat',
          to: '',
        },
        {
          from: 'minDailyOrderNum1Sun',
          to: '',
        },
        {
          from: 'minDailyOrderNum2Mon',
          to: '',
        },
        {
          from: 'minDailyOrderNum2Tue',
          to: '',
        },
        {
          from: 'minDailyOrderNum2Wed',
          to: '',
        },
        {
          from: 'minDailyOrderNum2Thu',
          to: '',
        },
        {
          from: 'minDailyOrderNum2Fri',
          to: '',
        },
        {
          from: 'minDailyOrderNum2Sat',
          to: '',
        },
        {
          from: 'minDailyOrderNum2Sun',
          to: '',
        },
        {
          from: 'configuredFlg',
          to: '',
        },
        {
          from: 'lastModifiedDatetime',
          to: '',
        },
        {
          from: 'lastModifiedUserName',
          to: '',
        },
        {
          from: 'storeinDowPatternId',
          to: '',
        },
      ]
      console.log({ columnLabelMapping })
      return columnLabelMapping
    },
  },
})
